import React, { useEffect, useContext } from "react";
import { graphql, navigate } from "gatsby";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { StoreContext } from "../../components/context/store";

// Components
import { ProductContent } from "../../components/product/product-content";

// SEO
import { PageSeo } from "../../components/global/page-seo";

const Product = ({ data }) => {
  const { client } = useContext(StoreContext);

  useEffect(() => {
    if (data.shopifyProduct === null || data.prismicProduct === null) {
      navigate("/fr/page-not-found/");
    }
  }, []);

  // if (client === null) return <></>;
  // if (data.shopifyProduct === null) return <></>;
  // if (data.prismicProduct === null) return <></>;

  return (
    <>
      <PageSeo
        title={
          data.prismicProduct !== null
            ? data.prismicProduct.data.title.text !== null
              ? data.prismicProduct.data.title.text
              : data.prismicProduct.data.title.text
            : null
        }
        image={
          data.prismicProduct !== null
            ? data.prismicProduct.data.product_image.url !== undefined
              ? data.prismicProduct.data.product_image.url
              : null
            : null
        }
        description={
          data.prismicProduct !== null
            ? data.prismicProduct.data.editorial_text !== null
              ? data.prismicProduct.data.editorial_text.text
              : null
            : null
        }
        url={
          data.shopifyProduct !== null
            ? `/products/${data.shopifyProduct.handle}`
            : null
        }
      />

      {client !== null &&
        data.shopifyProduct !== null &&
        data.prismicProduct !== null && (
          <ProductContent
            data={data}
            product={data.shopifyProduct}
            language={`french`}
            client={client}
          />
        )}
    </>
  );
};

export default withPrismicPreview(Product);

export const query = graphql`
  query ProductQueryFrench($handle: String, $id: String) {
    prismicProduct(id: { eq: $id }, lang: { eq: "fr-fr" }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        title {
          richText
          text
        }
        availability_text {
          richText
        }
        show_availability_text
        details {
          detail_title
          detail_text {
            richText
          }
        }
        price_on_demand
        editorial_text {
          richText
          text
        }
        material_swatch {
          gatsbyImageData(layout: FULL_WIDTH)
          alt
          dimensions {
            width
            height
          }
          fluid {
            aspectRatio
          }
        }
        product_description {
          richText
          text
        }
        product_image {
          gatsbyImageData(layout: FULL_WIDTH)
          alt
          url
          dimensions {
            width
            height
          }
          fluid {
            aspectRatio
          }
        }
        product_images {
          full_width_image
          product_image {
            gatsbyImageData(layout: FULL_WIDTH)
            alt
            dimensions {
              width
              height
            }
            fluid {
              aspectRatio
            }
          }
        }
        thumbnail {
          gatsbyImageData(layout: FULL_WIDTH)
          alt
          dimensions {
            width
            height
          }
          fluid {
            aspectRatio
          }
        }

        body {
          ... on PrismicProductDataBodyLinkedSectionCover {
            id
            slice_type
            primary {
              activity_title {
                richText
              }
              activity_text {
                richText
              }
              activity_image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData
                fluid {
                  aspectRatio
                  srcSet
                  src
                }
              }
            }
          }
          ... on PrismicProductDataBodyLinkedSection {
            id
            slice_type
            primary {
              link_title {
                richText
                text
              }
              link_text {
                richText
              }
              link_image {
                alt
                gatsbyImageData
                fluid {
                  aspectRatio
                  src
                  srcSet
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicProductDataBodyBigPush {
            id
            slice_type
            primary {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                      }
                    }
                  }
                }
              }
              big_push_title {
                richText
              }
              call_to_action {
                richText
              }
            }
          }
          ... on PrismicProductDataBodyCover {
            id
            slice_type
            primary {
              cover_image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                fluid {
                  aspectRatio
                }
              }
              cover_text {
                richText
              }
              cover_title {
                richText
              }
              cover_call_to_action {
                richText
              }
              image_position
              link {
                url
              }
            }
          }
          ... on PrismicProductDataBodyEdit {
            id
            slice_type
            primary {
              background_color
              journal_edit {
                type
                document {
                  ... on PrismicJournalEdit {
                    id
                    data {
                      title {
                        richText
                      }
                      articles {
                        article {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                              }
                            }
                            ... on PrismicArticle {
                              id
                              url
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicJournalCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicActivity {
                              id
                              url
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicProject {
                              id
                              url
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProductDataBodyEditorialCover {
            id
            slice_type
            primary {
              editorial_credits {
                richText
              }
              editorial_text {
                richText
              }
              editorial_title {
                richText
              }
              image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                fluid {
                  aspectRatio
                }
              }
              image_position
            }
          }
          ... on PrismicProductDataBodyEditorialImage {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicProductDataBodyEditorialImageWithText {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
              text {
                richText
              }
            }
          }
          ... on PrismicProductDataBodyEditorialImages {
            id
            slice_type
            primary {
              editorial_image_left {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              editorial_image_right {
                dimensions {
                  height
                  width
                }
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              image_left_background
              image_right_background
            }
          }
          ... on PrismicProductDataBodyVideo {
            id
            slice_type
            primary {
              video {
                type
                html
                embed_url
                provider_name
                id
              }
              video_size
            }
          }
          ... on PrismicProductDataBodyEditorialNote {
            id
            slice_type
            primary {
              editorial_note_title
              editorial_note_text {
                richText
              }
            }
          }
          ... on PrismicProductDataBodyEditorialQuote {
            id
            slice_type
            primary {
              editorial_quote {
                richText
              }
            }
          }
          ... on PrismicProductDataBodyEditorialRow {
            id
            slice_type
            primary {
              editorial_row_title {
                richText
              }
            }
            items {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 800)
                        fluid {
                          aspectRatio
                        }
                      }
                      categories {
                        category {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProductDataBodyEditorialText {
            id
            slice_type
            primary {
              editorial_text {
                richText
              }
            }
          }
          ... on PrismicProductDataBodyProductRowCarousel {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(aspectRatio: 0.71)
                        alt
                        dimensions {
                          width
                          height
                        }
                        fluid {
                          aspectRatio
                        }
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicProductDataBodyProductRow {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(aspectRatio: 0.71)
                        alt
                        dimensions {
                          width
                          height
                        }
                        fluid {
                          aspectRatio
                        }
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicProductDataBodyProductCardLarge {
            id
            slice_type
            primary {
              orientation
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData
                        alt
                        dimensions {
                          width
                          height
                        }
                        fluid {
                          aspectRatio
                        }
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
              }
              orientation
            }
          }
          ... on PrismicProductDataBodyEditorialIntroduction {
            id
            slice_type
            primary {
              editorial_introduction_text {
                richText
              }
            }
          }
          ... on PrismicProductDataBodyPushStory {
            id
            slice_type
            primary {
              background_color
              story {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      thumbnail {
                        alt
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                        gatsbyImageData
                        dimensions {
                          height
                          width
                        }
                      }
                      excerpt_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProductDataBodyTitleAndText {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_text {
                richText
              }
            }
          }
          ... on PrismicProductDataBodyNewsletter {
            id
            slice_type
            primary {
              newsletter {
                document {
                  ... on PrismicNewsletterSignup {
                    id
                    data {
                      background_color
                      email_placeholder
                      newsletter_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProductDataBodyActivities {
            id
            slice_type
            primary {
              activity_title {
                richText
              }
              activity_text {
                richText
              }
            }
          }
          ... on PrismicProductDataBodyCustomOrderForm {
            id
            slice_type
            items {
              textarea
              form_input_field
            }
          }
          ... on PrismicProductDataBodyLegalNoticeAddress {
            id
            slice_type
            primary {
              text_column_left {
                richText
              }
              text_column_right {
                richText
              }
            }
          }
          ... on PrismicProductDataBodyRefundForm {
            id
            slice_type
            primary {
              signature_text
              date_text
              delete_text
            }
            items {
              form_input_field
            }
          }
          ... on PrismicProductDataBodyCollections {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_text {
                richText
              }
            }
            items {
              image {
                alt
                fluid {
                  srcSet
                  src
                  aspectRatio
                }
                gatsbyImageData
              }
              name {
                richText
              }
              text {
                richText
              }
              collection {
                document {
                  ... on PrismicCollection {
                    id
                    url
                  }
                }
              }
            }
          }
          ... on PrismicProductDataBodyTeams {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_text {
                richText
              }
            }
            items {
              image {
                alt
                fluid {
                  srcSet
                  src
                  aspectRatio
                }
                gatsbyImageData
              }
              text {
                richText
              }
              name {
                richText
              }
            }
          }
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      title
      descriptionHtml
      description
      productType
      tags
      handle
      shopifyId
      id
      featuredImage {
        originalSrc
      }
      seo {
        description
        title
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        storefrontId
        selectedOptions {
          name
          value
        }
        image {
          gatsbyImageData
        }
      }
      metafields {
        value
        key
      }
      options {
        name
        values
        shopifyId
      }
    }
  }
`;
